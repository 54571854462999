<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Reimbursement">
      <template #page-header>
        <h2 class="page-title">Reimbursement</h2>
        <r-menu-tab :level="3" />
      </template>
      <template #action-bar>
        <div class="columns">
          <div class="column is-9">
            <div class="level">
              <div class="level-left">
                <b-button
                  tag="button"
                  class="is-command"
                  icon-left="plus"
                  @click.native="openModalRequest()"
                >
                  New
                </b-button>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <b-field class="search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isReimbursementPersonalLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-request-personal"
        >
          <b-table
            :data="reimbursementPersonal"
            :per-page="perPage"
            :loading="isReimbursementPersonalLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkbox-position="left"
            class="employment-directory-table table-log-activity-personal general-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="reimburse_type_name"
                label="Type of Reimburse"
                v-slot="props"
                sortable
                width="25%"
              >
                <span class="is-capitalize">
                  {{ props.row.reimburseTypeName }}
                </span>
              </b-table-column>

              <b-table-column field="date" label="Date" v-slot="props" sortable>
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="total_reimburse"
                label="Reimbursement Amount"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{
                    props.row.totalReimburse &&
                    `Rp ${props.row.totalReimburse.toLocaleString()}`
                  }}
                </span>
              </b-table-column>

              <b-table-column
                field="request_reason"
                label="Reason"
                v-slot="props"
                sortable
              >
                <span class="is-capitalize">
                  {{ props.row.requestReason }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>
              <b-table-column
                field="action"
                label="Action"
                v-slot="props"
                centered
              >
                <b-icon
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="
                    openModalRequest(reimbursementPersonal[props.index])
                  "
                ></b-icon>
                <b-icon
                  icon="eye"
                  custom-size="mdi-18px"
                  class="click"
                  @click.native="openSidebar(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>

        <b-sidebar
          v-if="isRightBarOpen && form != null"
          can-cancel
          fullheight
          :fullwidth="fullWidth"
          :overlay="true"
          right
          v-model="isRightBarOpen"
        >
          <div class="rightbar-detail-top-content-container">
            <div class="right-bar-detail-top-content">
              <p class="has-text-weight-black is-size-4 title">
                Reimbursement Details
              </p>
              <b-icon
                icon="close"
                class="x-close-btn"
                custom-size="mdi-18px"
                @click.native="closeSidebar"
              ></b-icon>
              <img
                class="profile-picture"
                :src="determineProfilePicture(currentUser)"
              />

              <p class="has-text-weight-black is-size-4 full-name">
                {{ currentUser.firstName }} {{ currentUser.middleName }}
                {{ currentUser.lastName }}
              </p>
            </div>
            <div class="columns is-multiline p-4 is-size-6">
              <div class="column is-4">Reimburse Type</div>
              <div class="column is-8 has-text-weight-bold">
                {{ selectedColumn.reimburseTypeName }}
                {{ selectedColumn.reimburseTypeId }}
              </div>
              <div class="column is-4">Date</div>
              <div class="column is-8 has-text-weight-bold">
                {{ formatDate(selectedColumn.date) }}
              </div>
              <div class="column is-4">Amount</div>
              <div class="column is-8 has-text-weight-bold">
                {{ 'Rp. ' + selectedColumn.totalReimburse }}
              </div>
              <div class="column is-4">Status</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.status }}
              </div>
              <div class="column is-4" v-if="selectedColumn.files.length > 0">
                Files
              </div>
              <div class="column is-8" v-if="selectedColumn.files.length > 0">
                <div class="tags">
                  <span
                    v-for="file in selectedColumn.files"
                    :key="file.id"
                    class="tag is-primary tag-small"
                  >
                    <div class="file-tag" @click="openFileUrl(file.url)">
                      {{ file.name }}
                    </div>
                  </span>
                </div>
              </div>
              <div class="column is-4">Reason</div>
              <div class="column is-8 has-text-weight-bold is-capitalize">
                {{ selectedColumn.requestReason }}
              </div>
              <div class="column is-12">
                <TimeOffApprovalHistoryComponent
                  :approval-history="selectedColumn.history"
                />
              </div>
              <div
                class="column is-12"
                v-if="selectedColumn.status === 'waiting'"
              >
                <b-button
                  expanded
                  @click="cancelReimbursement(selectedColumn.id)"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </b-sidebar>

        <r-page-modal
          :prompt-on-leave="false"
          prompt-message="Are you sure?"
          v-if="isModalOpen"
          @hide="closeModalRequest"
          :is-modal="true"
          key="modalKey"
          class="modal-log-activity-management modal-reimbursement"
          remove-scrolling
        >
          <template #page-content>
            <h3 class="modal-title has-text-weight-black">
              Request Reimbursement
              <span class="office-pointer-click is-pulled-right">
                <b-icon
                  icon="close"
                  size="is-small"
                  @click.native="closeModalRequest"
                ></b-icon>
              </span>
            </h3>
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(submit)"
                class="is-label-14px-normal form-timeoff-type"
              >
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <ValidationProvider
                      name="reimburse_type_id"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Reimbursement Type"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-select
                          v-model="form.reimburse_type_id"
                          expanded
                          placeholder="Reimbursement Type"
                          class="hover"
                          :disabled="isEditing && form.status !== 'waiting'"
                        >
                          <option
                            v-for="option in reimbursementTypeList"
                            :value="option.id"
                            :key="'attendance-' + option.id"
                          >
                            {{ option.name }}
                          </option>
                        </b-select>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <ValidationProvider
                      name="date"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Date"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-datepicker
                          placeholder="Date"
                          icon-right="calendar"
                          id="date-picker-calendar"
                          :max-date="maxDate"
                          :locale="locale"
                          v-model="form.date"
                          class="attendance-datepicker"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-datepicker>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <ValidationProvider
                      name="total_reimburse"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Reimbursement Amount"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <currency-input
                          v-model="form.total_reimburse"
                          currency="IDR"
                          locale="id"
                          class="currency-input"
                          :allow-negative="false"
                          :precision="{ min: 0, max: 0 }"
                          :distraction-free="false"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></currency-input>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12" v-if="formatUploadAppearance()">
                    <b-field label="Upload File">
                      <b-upload
                        v-model="form.files"
                        accept="image/*"
                        drag-drop
                        expanded
                      >
                        <section class="section">
                          <div class="content has-text-centered">
                            <p>
                              <b-icon icon="image" size="is-large"></b-icon>
                            </p>
                            <p>
                              Drag image here or
                              <span class="has-red-text">Browse</span>
                            </p>
                          </div>
                        </section>
                      </b-upload>
                    </b-field>
                  </div>
                  <div
                    class="column is-4"
                    v-if="formatUploadedImageAppearance()"
                  >
                    <label class="label">
                      {{ formatLabelAppearance() }}
                    </label>
                  </div>
                  <div
                    class="column is-8"
                    v-if="formatUploadedImageAppearance()"
                  >
                    <div class="tag is-primary">
                      <span class="file-tag">
                        {{ formatImageName() }}
                      </span>

                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile()"
                      ></button>
                    </div>
                  </div>

                  <div class="column is-12">
                    <ValidationProvider
                      name="request reason"
                      v-slot="{ errors }"
                      rules="required"
                    >
                      <b-field
                        label="Request Reason"
                        class="is-required"
                        :type="errors[0] && 'is-danger'"
                        :message="errors[0]"
                      >
                        <b-input
                          type="textarea"
                          v-model="form.request_reason"
                          placeholder="Please write your message"
                          :disabled="isEditing && form.status !== 'waiting'"
                        ></b-input>
                      </b-field>
                    </ValidationProvider>
                  </div>

                  <div class="column is-12">
                    <b-button
                      expanded
                      native-type="submit"
                      type="is-primary"
                      :loading="isSubmitting"
                      :disabled="isEditing && form.status !== 'waiting'"
                    >
                      {{ isEditing ? 'Update' : 'Request Reimbursement' }}
                    </b-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
        </r-page-modal>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { determineFontColorByStatus, showToast } from '@/services/util'
import moment from 'moment-timezone'
import TimeOffApprovalHistoryComponent from '../TimeOff/TimeOffApprovalHistoryComponent.vue'

export default {
  components: { TimeOffApprovalHistoryComponent },
  data() {
    const today = new Date()
    return {
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      total: 0,

      defaultSortOrder: 'desc',
      sortField: 'updated_at',
      sortOrder: 'desc',
      search: '',

      isReimbursementPersonalLoading: false,
      isReimbursementTypeLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      selectedColumn: null,
      isRightBarOpen: false,
      fullWidth: false,
      right: false,

      form: {
        id: null,
        reimburse_type_id: null,
        date: null,
        total_reimburse: null,
        files: null,
        request_reason: null,
        status: null,
      },

      attendanceOptions: [],
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      isSubmitting: null,
      locale: 'en-GB',
    }
  },
  computed: {
    ...mapGetters({
      reimbursementPersonal: 'reimbursement/getReimbursementRequestData',
      reimbursementTypeList: 'reimbursement/getReimbursementTypeData',
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadReimbursementPersonal:
        'reimbursement/fetchReimbursementRequestData',
      saveReimbursement: 'reimbursement/saveReimbursement',
      updateReimbursement: 'reimbursement/updateReimbursementRequest',
      actionLoadReimbursementType: 'reimbursement/fetchReimbursementTypeData',
    }),

    ...mapMutations({
      setReimbursementPersonal: 'reimbursement/setReimbursementRequestData',
    }),

    openModalRequest(row) {
      if (row) {
        this.isEditing = true
        this.form = {
          id: row.id,
          reimburse_type_id: row.reimburseTypeId,
          date: new Date(row.date),
          files: row.files[0],
          request_reason: row.requestReason,
          total_reimburse: row.totalReimburse,
          status: row.status,
        }
      }
      this.isModalOpen = true
    },

    closeModalRequest() {
      this.resetForm()
      this.isModalOpen = false
      this.isEditing = false
    },

    openFileUrl(fileUrl) {
      let fullUrl = process.env.VUE_APP_API_URL + fileUrl
      window.open(fullUrl, '_blank')
    },

    deleteDropFile() {
      this.form.files = null
    },

    formatUploadAppearance() {
      if (!this.isEditing && !this.form.files) {
        // ketika buat baru dan belum pilih file
        return true
      } else if (
        // ketika edit, belum pilih file,  status waiting
        this.isEditing &&
        !this.form?.files &&
        this.form?.status === 'waiting'
      ) {
        return true
      } else if (
        // ketika edit, belum ada existing, status waiting
        this.isEditing &&
        !this.form.files &&
        this.form?.status === 'waiting'
      ) {
        return true
      }
      return false
    },

    formatLabelAppearance() {
      if (!this.isEditing && this.form.files) {
        return 'File to upload'
      } else if (
        this.form?.files?.length === 0 &&
        this.form?.status === 'waiting'
      ) {
        return 'File to upload'
      } else {
        return 'Uploaded File'
      }
    },

    formatUploadedImageAppearance() {
      if (!this.isEditing && this.form.files) {
        return true
      } else if (this.isEditing && !this.form.files) {
        return false
      } else if (
        this.isEditing &&
        this.form.files &&
        this.form?.status === 'waiting'
      ) {
        return true
      } else if (
        this.isEditing &&
        this.form?.files?.length !== 0 &&
        this.form?.status === 'waiting'
      ) {
        return true
      }
    },

    formatImageName() {
      if (this.form.files) {
        return this.form.files?.name
      } else if (this.form?.files?.length >= 0) {
        return this.form?.files[0]?.name
      } else {
        return 'No image uploaded'
      }
    },

    async submit() {
      const form = new FormData()

      form.append('date', moment(this.form.date).format('YYYY-MM-DD'))
      form.append('reimburse_type_id', this.form.reimburse_type_id)
      form.append('total_reimburse', this.form.total_reimburse)
      form.append('request_reason', this.form.request_reason)

      this.isSubmitting = true
      if (!this.isEditing) {
        try {
          if (this.form.files) {
            form.append('images[]', this.form.files)
          }

          await this.saveReimbursement(form)
          showToast('Data is successfully added!', 'is-success', 'is-bottom')
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-bottom')
        }
      } else {
        try {
          if (this.form.files?.url !== this.form?.files?.[0]?.url) {
            form.append('images[]', this.form.files)
          }
          const data = { id: this.form.id, form }

          await this.updateReimbursement(data)
          showToast('Data is successfully updated!', 'is-success', 'is-bottom')
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-bottom')
        }
      }

      this.isSubmitting = false
      this.closeModalRequest()
    },

    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadReimbursementPersonalList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setReimbursementPersonal([])
      await this.loadReimbursementPersonalList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadReimbursementPersonalList()
    },

    resetForm() {
      this.form = {
        id: null,
        reimburse_type_id: null,
        date: null,
        total_reimburse: null,
        files: null,
        request_reason: null,
      }
    },

    async loadReimbursementPersonalList() {
      this.isReimbursementPersonalLoading = true

      try {
        const response = await this.actionLoadReimbursementPersonal({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementPersonalLoading = false
    },

    async loadReimbursementTypeList() {
      this.isReimbursementTypeLoading = true

      try {
        await this.actionLoadReimbursementType({
          perPage: 1000,
          page: 1,
        })
      } catch (err) {
        console.log(err)
      }
      this.isReimbursementTypeLoading = false
    },

    openSidebar(changeSchedule) {
      this.selectedColumn = changeSchedule
      this.isRightBarOpen = true
    },

    closeSidebar() {
      this.isRightBarOpen = false
    },

    determineProfilePicture(user) {
      if (!user.profilePictureUrl) {
        if (user.gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user.profilePictureUrl
    },

    async cancelReimbursement(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: `Are you sure? You can't undo this action.`,
      })

      if (res && res.isConfirmed) {
        const form = new FormData()

        form.append('status', 'canceled')
        this.isSubmitting = true
        try {
          form.append('_METHOD', 'PUT')
          const data = { id, form }
          await this.updateReimbursement(data)
          showToast('Request canceled', 'is-success', 'is-bottom')
          this.isRightBarOpen = false
        } catch (e) {
          showToast(e.response.message, 'is-danger', 'is-bottom')
        }
      }
    },
  },
  async mounted() {
    this.setReimbursementPersonal([])
    await this.loadReimbursementPersonalList()
    await this.loadReimbursementTypeList()
  },
}
</script>
